.header {
  background: #818cff;
  display: flex;
  font-size: 14px;
  padding: 10px;
}

.left {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
}

.right {
  flex: 1;
  text-align: right;
}

.nav {
  display: inline-block;
  vertical-align: top;
}

.n {
  border: 1px solid #fff;
  display: inline-block;
  color: #fff;
  font-weight: bold;
  font-size: 11px;
  padding: 5px 8px;
}

.nav-ul {
  list-style-type: none;
}

.nav-ul li {
  display: inline-block;
}

.nav-ul li:not(:last-child):after {
  content: ' | ';
  padding-right: 4px;
}

.nav-ul li a {
  display: inline-block;
  font-size: 10pt;
  text-decoration: none;
  color: #000;
}

.nav-ul li a:hover {
  color: #fff;
}
